var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    attrs: {
      "header-class": "py-50 text-airline px-1",
      "body-class": "pb-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          class: "fw-700 text-nowrap ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2')
        }, [_vm._v(" " + _vm._s(_vm.$t("reservation.customerInfo")) + " ")]), _c('BButton', {
          staticClass: "p-50 text-nowrap",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('toggleCollapse');
            }
          }
        }, [!_vm.isCollapsed ? _c('feather-icon', {
          attrs: {
            "icon": "ChevronDownIcon",
            "size": "17"
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "ChevronUpIcon",
            "size": "17"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('BCollapse', {
    attrs: {
      "visible": !_vm.isCollapsed
    }
  }, [_vm.customerToSelect.id ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.customer')) + ": "), _c('span', {
    staticClass: "mx-50 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.customerToSelect.fullName))]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger",
      modifiers: {
        "hover": true,
        "v-danger": true
      }
    }],
    staticClass: "p-50 text-nowrap",
    attrs: {
      "variant": "flat-danger",
      "title": _vm.$t('flight.removeCustomerSelected')
    },
    on: {
      "click": _vm.removeCustomerSelected
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon"
    }
  })], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('train.search.result.fullName'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "fullName"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('train.search.result.fullName')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('vue-autosuggest', {
          attrs: {
            "id": "fullName",
            "value": _vm.customerToSelect.fullName,
            "suggestions": _vm.customerSuggestion,
            "input-props": {
              id: 'fullName',
              placeholder: _vm.$t('flight.placeholderInput'),
              state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
              maxlength: '30',
              class: "form-control ".concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : '')
            },
            "section-configs": _vm.sectionConfigs,
            "get-suggestion-value": function getSuggestionValue(suggestion) {
              return _vm.getSuggestionValue(suggestion, 'fullName');
            }
          },
          on: {
            "input": function input(val) {
              return _vm.inputHandle('fullName', val);
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var suggestion = _ref.suggestion;
              return [_c('div', {
                staticClass: "cursor-pointer"
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b', [_vm._v(_vm._s(suggestion.item.lastName) + " " + _vm._s(suggestion.item.firstName))])]), _c('b-col', {
                staticClass: "text-nowrap",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" " + _vm._s(suggestion.item.phoneNumber) + " ")]), _c('b-col', {
                staticClass: "text-nowrap",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" " + _vm._s(suggestion.item.emailAddress) + " ")])], 1)], 1)];
            }
          }], null, true)
        }), validationContext.errors[0] ? _c('b-tooltip', {
          attrs: {
            "target": "fullName",
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "danger",
            "title": validationContext.errors[0]
          }
        }, [_c('span', {
          staticClass: "text-white py-25 mb-0"
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('train.search.result.idOrPassport'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "identifyNumber"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.idOrPassport')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "identifyNumber",
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "lazy-formatter": "",
            "formatter": _vm.formatterPassportOnly,
            "trim": "",
            "placeholder": _vm.$t('train.search.result.idOrPassport')
          },
          model: {
            value: _vm.customerToSelect.identifyNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.customerToSelect, "identifyNumber", $$v);
            },
            expression: "customerToSelect.identifyNumber"
          }
        }), validationContext.errors[0] ? _c('b-tooltip', {
          attrs: {
            "target": "identifyNumber",
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "danger",
            "title": validationContext.errors[0]
          }
        }, [_c('span', {
          staticClass: "text-white py-25 mb-0"
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Phone",
      "rules": "required|integer|phoneNumber"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "phone-contact"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.$t('flight.phoneNumber')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('vue-autosuggest', {
          attrs: {
            "id": "phone-contact",
            "value": _vm.customerToSelect.phone,
            "suggestions": _vm.customerSuggestion,
            "input-props": {
              id: 'phone-contact',
              placeholder: _vm.$t('flight.placeholderInput'),
              state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
              maxlength: '30',
              class: "form-control ".concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : '')
            },
            "section-configs": _vm.sectionConfigs,
            "get-suggestion-value": function getSuggestionValue(suggestion) {
              return _vm.getSuggestionValue(suggestion, 'phoneNumber');
            }
          },
          on: {
            "input": function input(val) {
              return _vm.inputHandle('phone', val);
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var suggestion = _ref2.suggestion;
              return [_c('div', {
                staticClass: "cursor-pointer"
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b', [_vm._v(_vm._s(suggestion.item.lastName) + " " + _vm._s(suggestion.item.firstName))])]), _c('b-col', {
                staticClass: "text-nowrap",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" " + _vm._s(suggestion.item.phoneNumber) + " ")]), _c('b-col', {
                staticClass: "text-nowrap",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" " + _vm._s(suggestion.item.emailAddress) + " ")])], 1)], 1)];
            }
          }], null, true)
        }), validationContext.errors[0] ? _c('b-tooltip', {
          attrs: {
            "target": "phone-contact",
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "danger",
            "title": validationContext.errors[0]
          }
        }, [_c('span', {
          staticClass: "text-white py-25 mb-0"
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "email-contact"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.email')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('vue-autosuggest', {
          attrs: {
            "id": "email-contact",
            "value": _vm.customerToSelect.email,
            "suggestions": _vm.customerSuggestion,
            "input-props": {
              id: 'email-contact',
              placeholder: _vm.$t('flight.placeholderInput'),
              state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
              class: "form-control ".concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : '')
            },
            "section-configs": _vm.sectionConfigs,
            "get-suggestion-value": function getSuggestionValue(suggestion) {
              return _vm.getSuggestionValue(suggestion, 'emailAddress');
            }
          },
          on: {
            "input": function input(val) {
              return _vm.inputHandle('email', val);
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var suggestion = _ref3.suggestion;
              return [_c('div', {
                staticClass: "cursor-pointer"
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b', [_vm._v(_vm._s(suggestion.item.lastName) + " " + _vm._s(suggestion.item.firstName))])]), _c('b-col', {
                staticClass: "text-nowrap",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" " + _vm._s(suggestion.item.phoneNumber) + " ")]), _c('b-col', {
                staticClass: "text-nowrap",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" " + _vm._s(suggestion.item.emailAddress) + " ")])], 1)], 1)];
            }
          }], null, true)
        }), validationContext.errors[0] ? _c('b-tooltip', {
          attrs: {
            "target": "email-contact",
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "danger",
            "title": validationContext.errors[0]
          }
        }, [_c('span', {
          staticClass: "text-white py-25 mb-0"
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }