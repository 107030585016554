<template>
  <BCard
    header-class="py-50 text-airline px-1"
    body-class="pb-0"
  >
    <template #header>
      <div :class="`fw-700 text-nowrap ${isMobileView ? 'font-medium-1' : 'font-medium-2'}`">
        {{ $t(`reservation.customerInfo`) }}
      </div>
      <BButton
        variant="flat-dark"
        class="p-50 text-nowrap"
        @click="$emit('toggleCollapse')"
      >
        <feather-icon
          v-if="!isCollapsed"
          icon="ChevronDownIcon"
          size="17"
        />
        <feather-icon
          v-else
          icon="ChevronUpIcon"
          size="17"
        />
      </BButton>
    </template>

    <BCollapse :visible="!isCollapsed">
      <div v-if="customerToSelect.id">
        {{ $t('flight.customer') }}: <span class="mx-50 font-weight-bold">{{ customerToSelect.fullName }}</span>
        <BButton
          v-b-tooltip.hover.v-danger
          variant="flat-danger"
          class="p-50 text-nowrap"
          :title="$t('flight.removeCustomerSelected')"
          @click="removeCustomerSelected"
        >
          <feather-icon icon="XIcon" />
        </BButton>
      </div>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <!-- ANCHOR - Họ và tên -->
          <validation-provider
            #default="validationContext"
            :name="$t('train.search.result.fullName')"
            rules="required"
          >
            <b-form-group label-for="fullName">
              <template #label>
                <div>
                  {{ $t('train.search.result.fullName') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>

              <vue-autosuggest
                id="fullName"
                :value="customerToSelect.fullName"
                :suggestions="customerSuggestion"
                :input-props="{
                  id:'fullName',
                  placeholder: $t('flight.placeholderInput'),
                  state: `${getValidationState(validationContext) === false ? false : null}`,
                  maxlength:'30',
                  class:`form-control ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`
                }"
                :section-configs="sectionConfigs"
                :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion,'fullName')"
                @input="val => inputHandle('fullName', val)"
              >
                <template slot-scope="{suggestion}">
                  <div class="cursor-pointer">
                    <b-row>
                      <b-col cols="12">
                        <b>{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</b>
                      </b-col>

                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.phoneNumber }}
                      </b-col>
                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.emailAddress }}
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </vue-autosuggest>
              <b-tooltip
                v-if="validationContext.errors[0]"
                target="fullName"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
                :title="validationContext.errors[0]"
              >
                <span class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </span>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- ANCHOR - Số CMND/Hộ chiếu -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('train.search.result.idOrPassport')"
            rules="required"
          >
            <b-form-group label-for="identifyNumber">
              <template #label>
                <div class="">
                  {{ $t('train.search.result.idOrPassport') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>

              <b-form-input
                id="identifyNumber"
                v-model="customerToSelect.identifyNumber"
                :state="getValidationState(validationContext) === false ? false : null"
                lazy-formatter
                :formatter="formatterPassportOnly"
                trim
                :placeholder="$t('train.search.result.idOrPassport')"
              />

              <b-tooltip
                v-if="validationContext.errors[0]"
                target="identifyNumber"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
                :title="validationContext.errors[0]"
              >
                <span class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </span>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- ANCHOR - Số điện thoại -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required|integer|phoneNumber"
          >
            <b-form-group label-for="phone-contact">
              <template #label>
                <div class="">
                  {{ $t('flight.phoneNumber') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>

              <vue-autosuggest
                id="phone-contact"
                :value="customerToSelect.phone"
                :suggestions="customerSuggestion"
                :input-props="{
                  id:'phone-contact',
                  placeholder: $t('flight.placeholderInput'),
                  state: `${getValidationState(validationContext) === false ? false : null}`,
                  maxlength:'30',
                  class:`form-control ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`
                }"
                :section-configs="sectionConfigs"
                :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion,'phoneNumber')"
                @input="val => inputHandle('phone', val)"
              >
                <template slot-scope="{suggestion}">
                  <div class="cursor-pointer">
                    <b-row>
                      <b-col cols="12">
                        <b>{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</b>
                      </b-col>

                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.phoneNumber }}
                      </b-col>
                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.emailAddress }}
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </vue-autosuggest>
              <b-tooltip
                v-if="validationContext.errors[0]"
                target="phone-contact"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
                :title="validationContext.errors[0]"
              >
                <span class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </span>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- ANCHOR - Email -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group label-for="email-contact">
              <template #label>
                <div>
                  {{ $t('flight.email') }}
                </div>
              </template>
              <vue-autosuggest
                id="email-contact"
                :value="customerToSelect.email"
                :suggestions="customerSuggestion"
                :input-props="{
                  id:'email-contact',
                  placeholder: $t('flight.placeholderInput'),
                  state: `${getValidationState(validationContext) === false ? false : null}`,
                  class:`form-control ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`
                }"
                :section-configs="sectionConfigs"
                :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion,'emailAddress')"
                @input="val => inputHandle('email', val)"
              >
                <template slot-scope="{suggestion}">
                  <div class="cursor-pointer">
                    <b-row>
                      <b-col cols="12">
                        <b>{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</b>
                      </b-col>

                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.phoneNumber }}
                      </b-col>
                      <b-col
                        cols="12"
                        class="text-nowrap"
                      >
                        {{ suggestion.item.emailAddress }}
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </vue-autosuggest>
              <b-tooltip
                v-if="validationContext.errors[0]"
                target="email-contact"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
                :title="validationContext.errors[0]"
              >
                <span class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </span>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </BCollapse>
  </BCard>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BButton, BTooltip,
  BCollapse, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'
import {
  computed, ref, watch, watchEffect,
} from '@vue/composition-api'
import { debounce } from 'lodash'

import apiCustomer from '@/api/customer'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { formatPhoneNumber, formatterPassportOnly } from '@core/comp-functions/forms/formatter-input'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTooltip,
    BCollapse,
    BFormInput,

    ValidationProvider,
    VueAutosuggest,
  },
  props: {
    customerData: {
      type: Object,
      default: () => {},
    },
    defaultCustomerData: {
      type: Object,
      default: () => {},
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState } = formValidation()

    const {
      blankCustomer,
      stateTicketSelectedArray,
    } = useTrainHandle()

    const customerToSelect = ref({ ...blankCustomer })
    const customerSuggestion = ref([])

    watch(() => props.defaultCustomerData, val => {
      if (val) {
        customerToSelect.value = {
          ...customerToSelect.value,
          ...props.defaultCustomerData,
        }
      }
    }, { deep: true, immediate: true })

    const isUpdateDataPax = computed(() => store.state['app-train'].isUpdateDataPax)
    const isDirtyInput = ref(false)

    watch(() => isUpdateDataPax.value, () => {
      if (customerToSelect.value.fullName && customerToSelect.value.identifyNumber && !isDirtyInput.value) return
      const firstPaxNotChild = stateTicketSelectedArray.value.find(storeData => storeData.find(ticket => ![2, 7].includes(ticket.passengerTargetCode)))?.[0]

      if (firstPaxNotChild) {
        if (!customerToSelect.value.fullName) customerToSelect.value.fullName = firstPaxNotChild.fullName
        if (!customerToSelect.value.identifyNumber) customerToSelect.value.identifyNumber = firstPaxNotChild.identifyNumber
      }
    })

    watch(customerToSelect, () => {
      emit('update:customerData', customerToSelect.value)
    }, { deep: true })

    function removeCustomerSelected() {
      isDirtyInput.value = false
      customerToSelect.value = { ...blankCustomer, ...props.defaultCustomerData }
    }

    const inputHandle = debounce((field, input) => {
      if (!isDirtyInput.value) {
        isDirtyInput.value = true
      }
      customerToSelect.value[field] = field === 'phone' ? formatPhoneNumber(input) : input
      apiCustomer.fetchCustomers({ searchText: customerToSelect.value[field] })
        .then(res => {
          customerSuggestion.value = [{ data: res.data.items }]
        })
    }, 500)

    const sectionConfigs = ref({
      default: {
        onSelected: selected => {
          customerToSelect.value = {
            id: selected.item.id,
            firstName: selected.item.firstName,
            lastName: selected.item.lastName,
            fullName: `${selected.item.lastName} ${selected.item.firstName}`,
            phone: selected.item.phoneNumber,
            email: selected.item.emailAddress,
            identifyNumber: selected.item?.identifyNumber ?? '',
          }
        },
      },
    })

    function getSuggestionValue(suggestion, val) {
      if (val === 'fullName') {
        return `${suggestion.item.lastName} ${suggestion.item.firstName}`
      }
      return suggestion.item[val]
    }

    return {
      refFormObserver,
      getValidationState,
      inputHandle,
      customerSuggestion,
      customerToSelect,
      sectionConfigs,
      getSuggestionValue,
      formatPhoneNumber,
      formatterPassportOnly,
      removeCustomerSelected,
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
  </style>
